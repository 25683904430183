<template>
   <div>
      <main>
         <!-- breadcrumb area start -->
         <section class="breadcrumb__area pt-20 pb-20 px-3">
            <!-- <div class="container wpx-800"> -->
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-sm">
                     <h6 class="color-theme mb-0">{{ produto.nome }}</h6>
                     <span class="weight-500">Cód. {{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</span>
                  </div>
                  <div class="w-max-content text-end mx-auto mt-10 mt-sm-0">
                     <router-link :to="'/produto/'+ produto.codigo" class="t-y-btn">Página do produto</router-link>
                  </div>
               </div>
            </div>
         </section>

         <!-- sessoes area start -->
         <section class="sessoes__area pb-40">
            <!-- <div class="container wpx-800"> -->
            <div class="container">
               <div class="row m-0">
                  <div class="col-12 postagem-html" v-html="produto.divulgacao"></div>
                  <div class="col-12 px-0 mt-200 pt-150 pb-150 mb-200 text-center" v-if="produto.divulgacao == null || String(produto.divulgacao).trim().length == 0">Sem descrição</div>
               </div>
            </div>
         </section>
      </main>

      <footer>
         <div class="footer__area footer-bg-2">
            <div class="footer__copyright footer__copyright-2 py-3 footer-bg-2">
               <!-- <div class="container wpx-800 custom-container"> -->
               <div class="container custom-container">
                  <div class="footer__copyright-text footer__copyright-text-2">
                     <div class="row justify-content-between align-items-center">
                        <div class="col-sm-3 text-center text-sm-start mb-3 mb-sm-0">
                           <router-link to="/"><img src="@/assets/cliente/img/logo/logo-white.png" alt="logo"></router-link>
                        </div>
                        <div class="col-sm-9 text-center text-sm-end">
                           <p>Copyright © <router-link to="/">CCM do Brasil.</router-link> Todos os direitos reservados. By <a href="https://agenciaoneway.com/" target="_blank"> OneWay.</a></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   </div>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'

export default {
   name: 'Produto_Divulgação',
   data : function () {
      return {
         produto: {'codigo': '00000000', 'apoioVideos': [], 'categoria': null, 'especificacoes': [], 'nome': 'Sem nome', 'variacoes': [], 'apoioMaterial': [], 
            'apoioImpressos': [], 'descricao': 'Sem descrição', 'duvidas': [], 'nomeMarca': 'Sem marca', 'fotoMarca': null, 
            'vistas': [], 'relacionados': [], 'fotos': [], 'telefoneRevendedor': null, 'divulgacao': ''}
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   watch: { 
      '$route.params.codigo': {
         handler: function () {
            this.getProduto()
         },
         deep: true,
         immediate: true
      }
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      getProduto : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getProduto',
            params: {
               id: this.$route.params.codigo
            }
			}).then(response => {
            this.produto = response.data

			}).catch((error) => {
				if (error.response != undefined) {
               if (error.response.status == 400) {
                  router.push('/').catch(function(){})
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
            window.scrollTo(0, 0)
            this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   }
}

</script>

<style scoped>

.footer__area img {
	width: 120px;
}

</style>